/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClearOutlined } from '@mui/icons-material'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { AppSearchIcon } from 'components/Icons/AppSearchIcon'
import { isEmpty, isEqual } from 'lodash'
import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Key } from 'utils/keyCodes'

type Props = {
  onSearch: (search: string, reason?: 'EnterKeyPressed') => void
  timeout?: number
  disableTimeout?: boolean
} & TextFieldProps

export type SearchTextFieldRef = {
  clear: () => void
  getValue: () => string
}

const SearchTextField = (props: Props, ref: Ref<SearchTextFieldRef>) => {
  const { onSearch, timeout, disableTimeout, ...textFieldProps } = props
  const { t } = useTranslation()

  const [search, setSearch] = useState(props.defaultValue as string)

  const searchTimeout = useRef(undefined)

  useImperativeHandle(ref, () => ({
    clear: () => setSearch(''),
    getValue: () => search,
  }))

  const handleSearchChange = (value: string) => {
    if (props.disableTimeout) {
      setSearch(value)
      onSearch(isEmpty(value) ? undefined : value)
      return
    }

    clearTimeout(searchTimeout.current)

    setSearch(value)

    searchTimeout.current = setTimeout(() => {
      onSearch(isEmpty(value) ? undefined : value)
    }, props.timeout ?? 250)
  }

  return (
    <TextField
      {...textFieldProps}
      value={search}
      onKeyDown={(e) => {
        if (e.key === Key.Enter) {
          e.preventDefault()
          onSearch(isEmpty(search) ? undefined : search, 'EnterKeyPressed')
        }
      }}
      onChange={(e) => handleSearchChange(e.target.value)}
      placeholder={t('common:search', 'search')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AppSearchIcon style={{ width: '1.5em', marginRight: '.5em' }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={search === ''}
              onClick={() => handleSearchChange('')}
              size="small"
              color="secondary"
            >
              <ClearOutlined fontSize="small" color="secondary" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default React.memo(
  forwardRef<SearchTextFieldRef, Props>(SearchTextField),
  isEqual
)
