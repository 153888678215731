import { AutocompleteChangeReason, useTheme } from '@mui/material'
import {
  KeywordWithTranslation,
  useKeywordsTranslation,
} from 'components/Common/ArticleSelector/useKeywordsTranslation'
import { useAppController } from 'customHooks/useAppController'
import { fetchCurrentProject } from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import { saveBomItemMaterials } from 'features/BillOfMaterials/store/asyncActions/saveBomItemMaterials'
import { bomItemActivitiesSelectors } from 'features/BillOfMaterials/store/selectors/activities/bomItemActivitiesSelectors'
import { projectOperationSelector } from 'features/BillOfMaterials/store/selectors/projectOperationPendingSelector'
import { debounce, groupBy, isEmpty, isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BoMItemActivityDto,
  KeywordDto,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { MaterialActions } from 'store/MaterialSelector/MaterialActions'
import { useAppDispatch, useAppSelector } from 'store/configureStore'
import { Key } from 'utils/keyCodes'
import { BomItemActivityController } from './BomItemActivityController'
import { getBomItemActivityKeywords } from './getBomItemActivityKeywords'

export const useActivityKeywords = (props: {
  bomItemPointer: BomItemPointer
  activityId: string
  /**
   * in some cases you don't want to get new keywords when selecting a keyword. For example when copying and pasting materials (no new options are show to the user)
   * */
  disableGetNewKeywords?: boolean
  /**
   * by default will save the tokens when you click on apply button. For batch update you want to save the tokens when you click on save button.
   * Use this prop to override the default behavior
   */
  onSaveHandler?: (tokens: BoMItemActivityDto['keywords']) => void
  /**
   * by default will save the tokens when you click on apply button. For batch update you want to save the tokens when you click on save button.
   */
  saveOnChange?: boolean
  /**
   * starts the component already open
   */
  startOpen?: boolean
  /**
   * initial keywords or modified ones case you want to use the component as controlled
   */
  initialKeywords?: BoMItemActivityDto['keywords']
  /**
   * shows only categories that are present in the array
   */
  categoriesToShow?: string[]
}) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const { translateKeyword, asArrayOfTranslatedKeywords } =
    useKeywordsTranslation()

  const initialActivity = useAppSelector((state) => {
    let activity: BoMItemActivityDto

    if (props.bomItemPointer) {
      activity = bomItemActivitiesSelectors.byActivityId(
        props.bomItemPointer,
        props.activityId
      )(state)
    }

    if (props.initialKeywords) {
      return Object.assign({}, activity, {
        keywords: props.initialKeywords,
      })
    }

    return activity
  }, isEqual)

  const [bomItemActivity, setBomItemActivity] =
    useState<BoMItemActivityDto>(initialActivity)

  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setBomItemActivity(initialActivity)
  }, [initialActivity])

  const resetActivity = useCallback(() => {
    setIsEditing(false)
    setBomItemActivity(initialActivity)
  }, [initialActivity])

  const { controller, loading } = useAppController(
    () => new BomItemActivityController()
  )
  //TODO: Check if needs special treatment for purchasing parts
  // const isPurchasingPart = useAppSelector(
  //   bomItemIsPurchasingPartSelector(bomItemPointer)
  // )

  const saving = useAppSelector(
    projectOperationSelector(
      saveBomItemMaterials.typePrefix.concat('/', props.bomItemPointer?.id)
    ),
    isEqual
  )

  const [savingArticle, setSavingArticle] = useState(false)

  useEffect(() => {
    if (saving?.requestStatus === 'fulfilled') {
      handleClose()
    }
  }, [saving])

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(props.startOpen ?? false)
  }, [props.startOpen])

  const [options, setOptions] = useState<KeywordWithTranslation[]>()

  const getNewKeywords = useCallback(
    (selectedKeywords: string[]) => {
      return debounce(
        async () => {
          try {
            setOptions([])
            const keywords = await controller.GetBomItemActivityMaterials(
              initialActivity.primaryWorkingStep ||
                WorkingStepType.SheetCutting,
              selectedKeywords
            )

            if (props.categoriesToShow?.length) {
              Object.keys(keywords.selectableTokens || {}).forEach(
                (category) => {
                  if (!props.categoriesToShow.includes(category)) {
                    delete keywords.selectableTokens[category]
                  }
                }
              )
            }

            setOptions((current) => {
              if (current) {
                return [
                  ...((keywords?.selectedTokens as KeywordWithTranslation[]) ||
                    []),
                  ...asArrayOfTranslatedKeywords(
                    keywords?.selectableTokens || {}
                  ),
                ].sort((a, b) =>
                  a.category.localeCompare(b.category, i18n.language)
                )
              } else {
                return asArrayOfTranslatedKeywords(
                  keywords.selectableTokens
                ).sort((a, b) =>
                  a.category.localeCompare(b.category, i18n.language)
                )
              }
            })

            return keywords
          } catch (err) {
            ShowException('project', err)
            return []
          }
        },
        120,
        { leading: false, trailing: true }
      )
    },
    [
      controller,
      initialActivity.primaryWorkingStep,
      props.categoriesToShow,
      asArrayOfTranslatedKeywords,
      i18n.language,
    ]
  )

  useEffect(() => {
    if (open) {
      !props.disableGetNewKeywords &&
        getNewKeywords(
          Object.values(bomItemActivity.keywords).flatMap((x) =>
            x.map((x) => x.originalKeyword)
          )
        )()
    }
  }, [
    open,
    getNewKeywords,
    bomItemActivity?.keywords,
    props.disableGetNewKeywords,
  ])

  const handleSaveArticle = async (
    articleId: string,
    selectedKeywords: KeywordDto[],
    isRawMaterialActivity: boolean
  ) => {
    try {
      setSavingArticle(true)
      await controller.SetBomItemActivityArticle(
        [
          {
            bomItemPointer: props.bomItemPointer,
            activityId: bomItemActivity.id,
          },
        ],
        articleId,
        selectedKeywords,
        isRawMaterialActivity
      )
    } catch (err) {
      ShowException('project', err)
      dispatch(fetchCurrentProject())
    } finally {
      dispatch(fetchCurrentProject())
      setTimeout(() => {
        setSavingArticle(false)
      }, 500)
    }
  }

  const _defaultSaveHandler = useCallback(
    async (keywordsToSave: BoMItemActivityDto['keywords']) => {
      try {
        if (isEqual(keywordsToSave, initialActivity.keywords)) {
          return
        }
        if (isEmpty(keywordsToSave)) {
          return
        }
        if (isEmpty(bomItemActivity?.id) || isEmpty(props.bomItemPointer?.id)) {
          return
        }

        await controller.SetBomItemActivityKeywords(
          [
            {
              bomItemPointer: props.bomItemPointer,
              activityId: bomItemActivity.id,
            },
          ],
          keywordsToSave,
          bomItemActivity.usesRawMaterial
        )

        handleClose()
      } catch (err) {
        ShowException('project', err)
        dispatch(fetchCurrentProject())
      }
    },
    [
      initialActivity.keywords,
      bomItemActivity.id,
      bomItemActivity.usesRawMaterial,
      props.bomItemPointer,
      controller,
      dispatch,
    ]
  )

  const handleSaveKeywords = props.onSaveHandler ?? _defaultSaveHandler

  const handleChange = useCallback(
    (
      e: ChangeEvent<unknown>,
      value: readonly KeywordDto[],
      reason: AutocompleteChangeReason,
      disableGetNewKeywords?: boolean
    ) => {
      switch (reason) {
        case 'selectOption':
        case 'removeOption':
          {
            if (value) {
              setBomItemActivity((current) => ({
                ...current,
                keywords: groupBy(value, (x) => x.category),
              }))
              if (!disableGetNewKeywords) {
                getNewKeywords(value.map((x) => x.originalKeyword))
              }

              setOpen(true)
              setIsEditing(true)

              if (props.saveOnChange) {
                handleSaveKeywords(
                  groupBy(
                    value,
                    (x) => x.category
                  ) as BoMItemActivityDto['keywords']
                )
              }
            }
          }
          break
        case 'blur':
          {
            if (
              Object.keys(getBomItemActivityKeywords(bomItemActivity))
                .length === 0
            ) {
              resetActivity()
            } else {
              // handleSaveKeywords(bomItemActivity.keywords)
            }
          }
          break
        case 'clear': {
          setBomItemActivity((current) => ({
            ...current,
            tokens: {},
          }))
        }
      }
    },
    [
      bomItemActivity,
      getNewKeywords,
      handleSaveKeywords,
      resetActivity,
      props.saveOnChange,
    ]
  )

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement> & { defaultMuiPrevented?: boolean }
  ) => {
    if (e.key === Key.Enter && options.length === 0) {
      handleSaveKeywords(bomItemActivity.keywords)
      setOpen(false)
    }
    if (e.key === Key.Escape && !props.saveOnChange) {
      resetActivity()
    }
    if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
      dispatch(
        MaterialActions.copyMaterial({
          baseBomItemPointer: props.bomItemPointer,
          activities: [bomItemActivity],
        })
      )
    }
    if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
      dispatch(
        MaterialActions.pasteMaterial(
          [props.bomItemPointer],
          initialActivity.primaryWorkingStep
        )
      )
      setIsEditing(true)
    }

    // e.stopPropagation()
  }

  const handleClose = () => {
    setOpen(false)
    setOptions([])
    setIsEditing(false)
  }

  return {
    options,
    loading,
    open,
    setOpen,
    getNewKeywords,
    handleSaveKeywords,
    saving,
    t,
    translateKeyword,
    theme,
    bomItemActivity,
    setBomItemActivity,
    resetActivity,
    handleKeyDown,
    handleClose,
    handleChange,
    isEditing,
    handleSaveArticle,
    savingArticle,
  }
}
