import { MoreVertOutlined } from '@mui/icons-material'
import { Divider, IconButton, Menu } from '@mui/material'
import { AppFeature } from 'features/AppModules/AppFeature'
import { FeatureKeys } from 'features/AppModules/FeatureKeys'
import { isEqual } from 'lodash'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { useRef, useState } from 'react'
import { useAppSelector } from 'store/configureStore'
import { CheckManufacturabilityMenuItem } from '../BoMItemMenu/MenuItems/CheckManufacturabilityMenuItem'
import { CopyActivityKeywordsMenuItem } from '../BoMItemMenu/MenuItems/CopyActivityKeywordsMenuItem'
import { DeleteMenuItem } from '../BoMItemMenu/MenuItems/DeleteMenuItem'
import { DownloadBomItemFilesMenuItem } from '../BoMItemMenu/MenuItems/DownloadBomItemFilesMenuItem'
import { EventLogMenuItem } from '../BoMItemMenu/MenuItems/EventLogMenuItem'
import { OpenDetailsSidePanelButton } from '../BoMItemMenu/MenuItems/OpenDetailsSidePanelButton'
import { OpenOnErpMenuItem } from '../BoMItemMenu/MenuItems/OpenOnErpMenuItem'
import { PasteActivityKeywordsMenuItem } from '../BoMItemMenu/MenuItems/PasteActivityKeywordsMenuItem'
import { PurchasingPartMenuItem } from '../BoMItemMenu/MenuItems/PurchasingPartMenuItem'
import { RegressionTestMenuItem } from '../BoMItemMenu/MenuItems/RegressionTestMenuItem'
import { RememberOrForgetGeometryAsPurchasingMenuItem } from '../BoMItemMenu/MenuItems/RememberOrForgetPurchasingMenuItem'
import { ReportRecognitionIssuesMenuItem } from '../BoMItemMenu/MenuItems/ReportRecognitionIssuesMenuItem'
import { projectSelectors } from '../store/selectors/projectSelectors'
import { PartTypeFilterMenuButton } from './PartTypeFilterMenuButton'

type Props = {
  bomItemPointer: BomItemPointer
}

export const PartTypeMenuButton = ({ bomItemPointer }: Props) => {
  const [open, setOpen] = useState(false)
  const buttonRef = useRef(undefined)

  const handleClose = () => setTimeout(() => setOpen(false))

  const selectedBomItemsIds = useAppSelector(
    projectSelectors.selectedBomItemsPointersSelector,
    isEqual
  )

  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        ref={buttonRef}
        size="small"
        color="inherit"
      >
        <MoreVertOutlined />
      </IconButton>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={buttonRef.current}
        sx={{
          '& .MuiPaper-root': {
            backgroundImage: 'none',
          },
        }}
      >
        <span>
          <AppFeature featureKey={FeatureKeys.bomItemDetails}>
            <OpenDetailsSidePanelButton
              bomItemPointer={bomItemPointer}
              onClose={handleClose}
            />
          </AppFeature>

          <AppFeature featureKey={FeatureKeys.quotation}>
            <PurchasingPartMenuItem
              variant={selectedBomItemsIds?.length > 0 ? 'toggle' : 'modal'}
              bomItemPointer={
                selectedBomItemsIds?.length > 0 ? null : bomItemPointer
              }
              onClose={handleClose}
            />
          </AppFeature>

          <PasteActivityKeywordsMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <CopyActivityKeywordsMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <Divider style={{ margin: 0 }} />
          <PartTypeFilterMenuButton
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <Divider style={{ margin: 0 }} />
          <RememberOrForgetGeometryAsPurchasingMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <CheckManufacturabilityMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <DownloadBomItemFilesMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <OpenOnErpMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />

          <Divider style={{ margin: 0 }} />
          <DeleteMenuItem
            bomItemPointer={
              selectedBomItemsIds.length > 0 ? null : bomItemPointer
            }
            onClose={handleClose}
          />
          <Divider style={{ margin: 0 }} />
          <ReportRecognitionIssuesMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <RegressionTestMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
          <EventLogMenuItem
            bomItemPointer={bomItemPointer}
            onClose={handleClose}
          />
        </span>
      </Menu>
    </>
  )
}
