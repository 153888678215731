import { Paper, Theme } from '@mui/material'
import { LoadingContainer } from 'components/Common/LoadingContainer'
import { TFunction } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Board from 'react-trello'
import { ProjectSummaryDto } from 'services/APIs/InternalAPI/internal-api.contracts'
import { ShowException } from 'store/Application/appActions'
import { makeStyles } from 'tss-react/mui'
import SearchTextField from '../../Common/SearchTextBox/SearchTextField'
import { ProjectsPageHelpers } from '../ProjectsPageHelpers'
import { useProjectList } from '../useProjectList'
import { ScrollableLane } from './KanbanComponents/ScrollableLane'
import { KanbanProjectSummaryCard } from './KanbanProjectSummaryCard'

import './KanbanView.sass'

const useStyles = makeStyles()((theme: Theme) => ({
  board: {
    width: '100%',
    margin: '0 auto',
    color: theme.palette.text.primary,
    '& .react-trello-lane': {
      height: 'calc(100vh - 240px)',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRight: `3px dashed ${theme.palette.divider}`,
      // borderRadius: theme.shape.borderRadius,
    },
  },
  toolbar: {
    padding: theme.spacing(2, 4),
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const getCards = (
  projectList: ProjectSummaryDto[],
  status: string
): ReactTrello.DraggableCard<ProjectSummaryDto>[] => {
  return (
    projectList
      ?.filter((x) => x.status === status)
      // .sort((x) => x.priority)
      .map((x: ProjectSummaryDto) => ({
        id: x.id,
        title: x.projectReference,
        description: JSON.stringify(x),
        label: x.orderNumber,
        metadata: x,
      }))
  )
}

const getKanbanConfiguration = (
  projectStates: string[],
  projectList: Array<ProjectSummaryDto>,
  t: TFunction
) => {
  return {
    lanes:
      projectStates?.map((state) => {
        return {
          id: state.toLowerCase(),
          title: t(`common:${state}`, state),
          label:
            projectList?.filter((x) => x.status === state).length.toString() ||
            '0',
          cards: getCards(projectList, state),
        }
      }) || [],
  } as ReactTrello.BoardData<ProjectSummaryDto>
}

export const ProjectsKanban = (props: {
  isFirstRender: boolean
  currentQuery: string
  onQueryChange: (query: string) => void
}) => {
  const { classes } = useStyles()
  const navigate = useNavigate()

  const {
    onlyMyProjects,
    setOnlyMyProjects,
    projectList,
    projectStates,
    updateProjectListPaged,
    updateProjectStatus,
    loading,
    connectToUpdates,
    disconnectFromUpdates,
    handleSetPriority,
    showCanceledProjects,
    setShowCanceledProjects,
  } = useProjectList(props.isFirstRender)

  const { t } = useTranslation()
  const [kanbanConfiguration, setKanbanConfiguration] = useState<
    ReactTrello.BoardData<ProjectSummaryDto>
  >(getKanbanConfiguration(projectStates, projectList, t))

  const lastSearch = useRef<string | undefined>(props.currentQuery)

  useEffect(() => {
    updateProjectListPaged({
      page: 0,
      pageSize: 250,
      search: lastSearch.current,
    }).catch((error) => ShowException('project list', error))
  }, [updateProjectListPaged])

  useEffect(() => {
    setKanbanConfiguration(
      getKanbanConfiguration(projectStates, projectList, t)
    )
    // boardRef.current?.forceUpdate()
  }, [projectList, projectStates, t])

  useEffect(() => {
    connectToUpdates(() =>
      updateProjectListPaged({
        page: 0,
        pageSize: 250,
        search: lastSearch.current,
      })
    )

    return () => {
      disconnectFromUpdates()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectToUpdates, disconnectFromUpdates])

  const handleDragEnd = async (
    cardId: string,
    sourceLaneId: string,
    targetLaneId: string,
    position: number,
    cardDetails: ProjectSummaryDto
  ) => {
    try {
      await updateProjectStatus(
        cardDetails.id,
        sourceLaneId,
        targetLaneId,
        position
      )
    } catch (err) {
      ShowException('update project', err)
      updateProjectListPaged({
        page: 0,
        pageSize: 250,
        search: lastSearch.current,
      })
    }
  }

  const onSetPriority = async (projectId: string, priority: number) => {
    try {
      await handleSetPriority(projectId, priority)
    } catch (err) {
      ShowException('set priority', err)
      updateProjectListPaged({
        page: 0,
        pageSize: 250,
        search: lastSearch.current,
      })
    }
  }

  const handleCardClick = (cardId: string) => {
    navigate(`/app/project/${cardId}`)
  }

  const handleSearch = (search: string) => {
    lastSearch.current = search
    updateProjectListPaged({ page: 0, pageSize: 250, search: search })
    props.onQueryChange(search)
  }

  const handleReload = () => {
    updateProjectListPaged({
      page: 0,
      pageSize: 250,
      search: lastSearch.current,
    })
  }

  const handleOnlyMyProjects = (filterMyProjects: boolean) => {
    setOnlyMyProjects(filterMyProjects)
  }

  const KanbanCard = (props) => {
    return (
      <KanbanProjectSummaryCard
        {...props}
        loading={loading}
        setPriority={onSetPriority}
      />
    )
  }

  return (
    <Paper className={classes.board}>
      <div className={classes.toolbar}>
        <ProjectsPageHelpers
          handleOnlyMyProjects={handleOnlyMyProjects}
          onlyMyProjects={onlyMyProjects}
          handleReload={handleReload}
          reloading={loading['GetProjectsListPaged']}
          showCanceledProjects={showCanceledProjects}
          handleShowCanceledProjects={setShowCanceledProjects}
        />
        <SearchTextField
          onSearch={handleSearch}
          defaultValue={props.currentQuery}
        />
      </div>
      <span>
        <LoadingContainer loading={!kanbanConfiguration}>
          <span>
            {/* <Board data={data} draggable /> */}
            <Board
              data={kanbanConfiguration}
              components={{
                Card: KanbanCard,
                ScrollableLane: ScrollableLane,
              }}
              hideCardDeleteIcon
              handleDragEnd={handleDragEnd}
              onCardClick={handleCardClick}
              style={{
                backgroundColor: 'transparent',
              }}
            />
          </span>
        </LoadingContainer>
      </span>
    </Paper>
  )
}
